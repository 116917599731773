import React from 'react'
import { Link } from 'gatsby'
import { Link as ChakraLink, useTheme } from '@chakra-ui/react'
import { motion } from 'framer-motion'

const MotionLink = motion(ChakraLink)

type CTAProps = {
  onClick?: () => void
}

const CTA: React.FC<CTAProps> = ({ onClick }) => {
  const theme = useTheme()

  return (
    <MotionLink
      display="inline-block"
      //position="absolute"
      //top="29.8rem"
      //left="55rem"
      mt="-0.3rem"
      mr="-0.6rem"
      width="100%"
      as={Link}
      transition={{ type: 'spring', duration: 0.05 }}
      whileHover={{
        scale: 1.05,
        boxShadow: theme.shadows['xl'],
      }}
      whileTap={{ scale: 0.95 }}
      layerStyle="cta"
      textStyle="cta"
      onClick={onClick}
      to="/renovar"
      textDecoration="none !important"
      color="white"
      boxShadow="md"
    >
      Renovar una unidad
    </MotionLink>
  )
}

export default CTA
