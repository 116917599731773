// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-alquilar-tsx": () => import("./../../../src/pages/alquilar.tsx" /* webpackChunkName: "component---src-pages-alquilar-tsx" */),
  "component---src-pages-contacto-tsx": () => import("./../../../src/pages/contacto.tsx" /* webpackChunkName: "component---src-pages-contacto-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-precios-tsx": () => import("./../../../src/pages/precios.tsx" /* webpackChunkName: "component---src-pages-precios-tsx" */),
  "component---src-pages-renovar-tsx": () => import("./../../../src/pages/renovar.tsx" /* webpackChunkName: "component---src-pages-renovar-tsx" */),
  "component---src-pages-tos-tsx": () => import("./../../../src/pages/tos.tsx" /* webpackChunkName: "component---src-pages-tos-tsx" */)
}

