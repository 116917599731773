module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"C:\\Users\\matias\\Downloads\\guardalo-frontend (2)\\guardalo-frontend\\src\\components\\Layout.tsx"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Guardalo.com","short_name":"Guardalo","start_url":"/","background_color":"#1E3264","theme_color":"#1E3264","display":"minimal-ui","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"23c360e39298f5683ea662e98e09458f"},
    },{
      plugin: require('../node_modules/gatsby-plugin-matomo/gatsby-browser.js'),
      options: {"plugins":[],"siteId":"1","matomoUrl":"https://guardalo.com.ar/matomo","siteUrl":"https://guardalo.com.ar"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
